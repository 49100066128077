.adminView select, .adminView select:hover {
	font-size: 1.3em;
}
.userTable {
	display: table;
	border: 2px solid gray;
	margin: 0px;
	border-collapse: collapse;
}
.userTable > div {
	display: table-row;
}
.userTable > div > div {
	border: 1px solid lightgray;
	display: table-cell;
	padding: 4px 10px;
	text-align: left;
}

.userTable .tableHeader {
	font-weight: bold;
	text-align: center;
}
.userTable .selectedRow > div {
	font-weight: bold;
}
.clickDiv {
	background-color: #E0E6F8;
	text-decoration: underline;
}
.clickDiv:hover {
	cursor: pointer;
	text-decoration: none;
	background-color: #CECEF6;
}
.displayForm {
	border: 1px solid gray;
	border-radius: 6px;
	padding: 0.2em 0.4em;
	margin: 0.5em;
}

.selectGroup {
	line-height: 1.15;
}

div.yes {
	background-color: #E0F8E0;
}
div.no {
	background-color: #F6CECE;
}


.admin-mode {
	display: flex;
	flex-direction: row;
}
.admin-mode > * {
	flex: 1 1 auto;
}
.admin-mode :focus {
	outline: none;
}
.admin-mode button {
	color: #5cb85c;
	background-color: white;
}
.admin-mode > .selected {
	/* color: white;
	background-color: #5cb85c; */
	font-weight: bold;
	text-decoration: underline;
}
