body { background-color: white; }
.lessonPlan {
	position: relative;
	background-color: white;
	margin: 2em;
	padding: 1em;
	border: 1px solid black;
	box-shadow: 0px 0px 10px 6px rgba(180, 180, 180, 0.6);
}
.lessonPlan .x {
	position: absolute;
	right: 10px; top: 10px;
	cursor: pointer;
}
.lessonPlan > h3 {
	margin-top: 0;
	margin-bottom: 0.5em;
	font-size: 1.3em;
}
.lessonPlan > p {
	margin: 0.5em 0em 0.5em 0em;
}

.lessonPlan .content {
	border: 1px solid gray;
	box-shadow: 4px 4px 0px #ccc;
	margin: 1em 0em;
	padding: 0.7em;
	white-space: pre-wrap;
}

.lessonPlan .tags {
	cursor: default;
}
.lessonPlan .tags > * {
	display: inline-block;
	border-radius: 0.3em;
	background-color: #d8d8d8;
	padding: 0.25em 0.6em;
	margin-left: 0.4em;
	margin-right: 0.4em;
}
.lessonPlan .tags > :first-child {
	margin-left: 0;
}
.lessonPlan .tags > :hover {
	background-color: #e5e5e5;
}

.lessonPlan .grid {
	display: grid;
	grid-template-columns: minmax(10em, auto) 1fr;
}
.lessonPlan .grid > :nth-child(odd) {
	font-weight: bold;

	border-bottom: 1px dashed lightgray;
}
.lessonPlan .grid > * {
	margin-bottom: 0.5em;
}
