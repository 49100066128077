.tabview {
	border: 1px solid transparent;
	display: block;
	margin: auto;
	/*margin: 0px 0px -1px;*/
	padding: 0px;
	/*font-weight: 600;*/
	/*color: #bbb;*/
	/*background-color: #70a366;*/
	text-align: left;
	vertical-align: top;
	z-index: 1;
}

.tabview:before {
  font-family: fontawesome;
  font-weight: normal;
  margin-right: 10px;
  position: relative;
  bottom: -8px;
  background-color: hotpink;
}

/*
Media queries in this document:
(max-width: 1048px)
max-width: 760px)
(max-width: 700px)
max-width: 400px)
*/

input[readonly], textarea[readonly] {
	background-color: #E5E5E5;
}



.tabbtn {
	color: #bbb;
	display: inline-block;
	font-weight: 600;
    margin: 0px 2px -1px 1px;  /* top right bottom left */
	padding-left: 20px;
	padding-right: 30px;
	padding-top: 11px;
	padding-bottom: 19px;
    text-align: center;
}
.tabbtn:hover {
	color: #888;
	cursor: pointer;
}
.tabbtn:hover, .tabbtn.checked {
	border: 1px solid #dfdfdf;
	border-top-width: 2px;
	padding-left: 19px;
	padding-right: 29px;
}

.tabbtn.checked {
	color: #555;
	border-color: #ddd;
	border-top: 2px solid #70a366;
	border-bottom: 1px solid #fff;
}

.tabbtn img {
	position: relative;
	top: 5px;
	left: -5px;
}

.imgA_active { content: url('/img/flik_A.png'); alt: 'A'; }
.imgB_active { content: url('/img/flik_B.png'); alt: 'B'; }
.imgC_active { content: url('/img/flik_C.png'); alt: 'C'; }
.imgD_active { content: url('/img/flik_D.png'); alt: 'D'; }
.imgA { content: url('/img/flik_A_grey.png'); alt: 'A'; }
.imgB { content: url('/img/flik_B_grey.png'); alt: 'B'; }
.imgC { content: url('/img/flik_C_grey.png'); alt: 'C'; }
.imgD { content: url('/img/flik_D_grey.png'); alt: 'D'; }



.tabbody {
	border: 1px solid #ddd;
	font-size: 1.1em;
	padding: 2em;
	min-height: 300px;

	/*padding-right: 16px;
	border-top: none;
	margin: 0px;
	margin-top: -22px;
	padding: 2px;*/
}
.tabbody .space {
	padding: 24px 0px 24px 48px;
	/*background-color: beige;*/
}
.tabbody li {
	margin: 10px 0px;
}
.tabbody h2:not(:first-child) {
	margin-top: 40px;
}
.tabbody h2 {
	line-height: 1.3em;
}
.tabbody #f3btnsubject {
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.349019607843137);
}


.linknext1 img, .linknext2 img, .linknext3 img, .linkstep img {
	width: 15px;
	vertical-align: middle;
}
.linknext1, .linknext2, .linknext3, .linkstep {
	color: #999;
	cursor: pointer;
	display: inline-block;
	padding: 20px;
	text-decoration: none;
	padding-top: 20px;
}


/* Form for questions to user */
#f1error, #f2error, #f3error, #f4error {
	color: red;
	/*font-weight: bold;*/
}

.form { margin-top: 40px; }
.form > div {
	/*border: 1px solid lightgray;*/
	/*border-bottom: 1px solid lightgray;*/
	margin: 8px 0px 8px 0px;
	padding: 10px 0px 20px 0px;
	/*background-color: lime;*/
}
.form textarea {
	margin-bottom: 3em;
}
.form > div::after, .whensubmitted::before {
	margin-top: 30px;
	content: '';
	display: block;
	border-bottom: 1px solid lightgray;
	text-align: center;
	position: relative;
	/*left: 25%;*/
	left: 0%;
	width: 50%;
	height: 0px;
}
.form > div:last-child::after {
	display: none;
}
.form > div:last-child {
	border: none;
}
.form h3 {
	margin-top: 20px;
	margin-bottom: 2px;
}
button {
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.349019607843137);
	color: #fff;
	background-color: #5cb85c;
	border: none;
	border-radius: 4px;
	margin-top: .3em;
	padding: 6px 18px;
	padding: .4em .8em;
	line-height: 1.429;
	min-height: 50px;
	font-size: 1.2em;
	touch-action: manipulation;
	user-select: none;
}
button:hover {
	background-color: #449d44;
	border-color: #398439;
}
button:disabled {
	background-color: #ACB7AC;
	cursor: not-allowed;
}
button:hover:disabled {
	background-color: #949E94;
	border-color: #7A8279;
}

.form ul {
	margin-top: 0px;
	margin-left: 10px;
}
.form ul > li {
	display: inline-block;
	margin-right: 20px;
	background-color: #eee;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	padding-left: 8px;
	min-width: 150px;
	line-height: 3em;
}
.form ul > li button {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	background-color: #d9534f;
	border-color: #d43f3a;
	margin-left: 8px;
	float: right;
	line-height: 1.7em;
}
-chrome-document url-prefix  /* targets Chrome only */
.form ul > li {
	line-height: 2.5em;
}

.form ul > li button:hover {
	background-color: #c9302c;
    	border-color: #ac2925;
}
.form input[type="text"], .form input[type="password"], .form select, .form textarea {
	padding: 6px 12px 6px 12px;
	margin: 1em 8px .5em 30px;
	box-sizing: border-box;
	min-width: 40%;
	min-height: 50px;
	font-size: 1.2em;
	border: none;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.349019607843137);
}
.form .classyear {
	margin-left: 1em;
}
.form .gcError, .form .gcStatus { margin-left: 2em; }
.form .gcError { color: red; }
.form .gcStatus { color: green; }
select:hover {
	font-size: 1.2em ;
}
input.large {
	display: block;
	width: 80%;
}
textarea {
	width: 80%;
	rows: 3;
}
.form select {
	padding-top: 9px;
	padding-bottom: 8px;
}

/* ul.errors {

}
ul.errors > li {
	background: none;
	color: red;
	display: block;
	border: none;
	line-height: 1.2em;
	margin: 10px 0px;
} */

.form .errors {
	position: absolute;
	color: red;
	line-height: 1.2em;
	margin: 10px 0px;
}



@media screen and (-webkit-min-device-pixel-ratio:0) {  /*safari and chrome*/
	.form select {
	    height:50px;
	    line-height:50px;
	}
}
.form  select::-moz-focus-inner { /*Remove button padding in FF*/
border: 0;
padding: 0;
}
@-moz-document url-prefix() { /* targets Firefox only */
.form select {
	-moz-appearance: none;
	text-indent: 0.01px;
	text-overflow: "";
    	padding: 12px 0px;
}
.form select > option {
	font-size: 1.2em;
}
.form .styled_select:after {
	position: absolute;
	top: 0;
	right: 0;
	width: 32px;
	height: 100%;

	speak: none;
	content: '';
	z-index: 1;}
}

@media screen\0 { /* IE Hacks: targets IE 8, 9 and 10 */
.form select {
    height:50px;
    line-height:50px;
}
}

input[type="text"]:active {
	/*border-color: green;*/
}
.range {
	/*background-color: lime;*/
	/*padding-top: 10px;*/
	min-width: 200px;
	width: 50%;
	position: relative;
	margin: 20px 0px;
}
.range span {
	vertical-align: top;
	padding-top: 8px;
	display: inline-block;
	/*margin: auto 0px;*/
	position: absolute;
	top: 1em;
}
.range span:nth-child(2) { left: 0px; }
.range span:nth-child(3) { right: 0px; }
.range [type="range"] {
	display: inline-block;
	width: 100%;
}

.classyear {
	/*margin-left: 30px;
	background-color: red;*/
	margin-bottom: .5em;
	width: 100%;
}
.classyear ~ label {
	padding: .5em 0;
	width: 8%;
	background-color: #fff;
	display: inline-block;
}
.classyear ~ label:hover {
	background-color: #eee;
	cursor: pointer;
}
.classyear ~ label input {
	cursor: pointer;
}


.whensubmitted {
	margin-top: 40px;
}
/*.whensubmitted::before {
	content: '<hr>';
	border: 1px solid lightgray;
	width: 50%;
	text-align: left;
}*/

/* =========================== lesson plan cards =========================== */
.lessonplans {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.card {
	background-color: #f8f8f8;
	border: 1px solid gray;
	/*border-radius: 1px;*/
	box-shadow: 4px 4px 0px #ccc;
	display: inline-block;
	flex: 0 1 160px;
	margin: 10px;
	max-width: 160px;
	vertical-align: top;
	overflow-wrap: break-word;
	padding: 20px 20px 60px 20px;
	position: relative;
}
.card:hover {
	background-color: #fff;
}
.card img:hover {
	opacity: 0.5;
}
.card h2 {
	font-size: 1em;
	margin-top: 0px;
}
.preview, .settings {
	/*background-color: #ddd;*/
	width: 32px; height: 32px;
}
.preview:hover, .settings:hover { cursor: pointer; }
.preview { position: absolute; left: 20px; bottom: 20px; }
.settings { position: absolute; right: 20px; bottom: 20px; }
.settings .disabled {
	opacity: 0.5;
	background-color: red;
}






/* ============================= media queries ============================= */
/*.mobile .tabbody {
	font-size: 1.1em;
}
.mobile .tabbody input, .mobile .tabbody select {
	font-size: 1.2em;
}
.mobile .tabbody button {
	font-size: 1.4em;
}*/

@media (max-width: 1048px) {
	/* images on smaller screens should take percent based space */
	.tabbody img {
		max-width: 80%;
	}

	/* larger text on smaller screens */
	.tabbody {
		font-size: 1.1em;
	}
	.tabbody input, .tabbody select, .tabbody textarea {
		font-size: 1.1em;
	}
	.tabbody button {
		font-size: 1.3em;
	}
	.form ul > li button {
		line-height: 1.4em;
	    }

	.card {
		display: block;
		max-width: 100%;
		flex-basis: 100%;
	}
}

/* labels are used in the tab view */
@media (max-width: 768px) { /*850px*/
	.classyear ~ label {
		width: 100%;
	}
	.f3cc{
		width: 10%;
	}
	.tabbody img {
		max-width: 80%;
	}
	.form input, .form select {
		margin: 1em 0;
		width: 100%;
	}
	.form input[type=checkbox] {
		width: 10ß%;
	}
	.form input[type="text"], .form input[type="password"], .form select, .form textarea {
		margin: 1em 0;
		width: 100%;
	    }
	.tabbody #f3btnsubject {
		width: 100%;
	    }
	.form ul {
		-webkit-padding-start: 0px;
	}
	.form ul > li {
	width: 100%;
	-webkit-padding-start: 9px;
	box-sizing: border-box;
	}


	/*DEBUG body {background-color:lime;}*/
}
@media (max-width: 700px) {
	/* prevent inputs and selects from being wider than the screen */
	/*.form input, .form select {
		max-width: 100%;
	}*/
}
@media (max-width: 400px) {
	/* decrease padding on small screens */
	.tabbtn {
		padding: 15px;
	}
	.tabbody button {
		font-size: 1.2em;
	    }
	/*DEBUG body {background-color:red;}*/
}
