.admin section {
	border: 1px solid lightgray;
	box-shadow: 4px 4px 12px 1px rgba(135,135,135,1);
	margin: 20px 0px;
	padding: 10px;
}

.admin .grouplist {
	display: block;
	padding-bottom: 8px;
}
.admin input[type="radio"] {
	height: 1.3em;
	width: 1.3em;
	position: relative;
	top: 5px;
}
.admin textarea {
	display: block;
}

.admin .memberlist button {
	padding: 4px 10px;
	min-height: 12px;
	margin-left: 12px;
}

.adminButton:hover, .themeToggleButton:hover {
	opacity: 1.0;
}
.adminButton, .themeToggleButton {
	position: absolute;
	font-size: 0.8em;
	z-index: 2;
	opacity: 0.2;
	background: lightgray;
	color: black;
}
.adminButton {
	top: 50px;
	left: 0px;
}
.themeToggleButton {
	top: 100px;
	left: 0px;
}
